import { useEventEmitter, useMemoizedFn } from 'ahooks'
import constate from 'constate'
import { message } from 'antd';
import { useCurrentUser } from './user.hook'
import { getSignature } from '../utils/signature'
import { logger as logstashLogger } from './logger.hook/logstash'
import { ILogType } from './logger.hook/log.type';
import { useEffect, useRef, useState } from 'react';
import { IInnerShop } from '../api/user.api';

const testUser = true
const metric = getSignature()

interface ILogMessage {
    type: ILogType;
    parameter?: {
        [key: string]: any;
    }
}

export const [GlobalScopeProvider, useGlobalScope] = constate(() => {
    const SCOPE_KEY_INNERSHOP = 'currentInnerShop';
    const [messageApi, contextHolder] = message.useMessage();
    const containerId = 'container';
    const [innerShop, setInnerShop] = useState<IInnerShop | null>(null);
    const firstLoad = useRef(true);

    const { user, innerShopIds, innerShopList } = useCurrentUser()

    const logEmitter = useEventEmitter<{ vinStr: String, log: ILogMessage, globalLog: boolean }>();
    const dealLog = useMemoizedFn((vinStr: string, obj: ILogMessage) => {
        const { type, parameter } = obj
        const log_id = getSignature()

        const body = {
            type,
            __time_nano__: Date.now(),
            log_id,
            parameter: { vinStr, metric, userId: user?.id, webVersion: '1v12', ...parameter },
        }
        console.log("log", body)
        if (!testUser) {
            logstashLogger.trace(body)
        }
    })

    const onSwitchInnerShop = (innerShop: IInnerShop | null) => {
        setInnerShop(innerShop)
        if (innerShop) {
            localStorage.setItem(SCOPE_KEY_INNERSHOP, JSON.stringify(innerShop))
        } else {
            localStorage.removeItem(SCOPE_KEY_INNERSHOP)
        }
    }
    useEffect(() => {
        const lInnerShop = localStorage.getItem(SCOPE_KEY_INNERSHOP)
        if (firstLoad.current && innerShopIds.length > 0) {
            firstLoad.current = false;
            if (lInnerShop) {
                const cInnerShop = JSON.parse(lInnerShop)
                if (innerShopIds.includes(cInnerShop.innerShopId)) {
                    setInnerShop(cInnerShop);
                }
            } else if (innerShopList) {
                onSwitchInnerShop(innerShopList[0])
            }
        }
    }, [innerShopIds, innerShopList, SCOPE_KEY_INNERSHOP])

    return { logEmitter, messageApi, contextHolder, dealLog, containerId, setInnerShop: onSwitchInnerShop, innerShop }
})