import constate from 'constate'
import mqtt from 'mqtt'
import { useMemo, useState } from "react"
import { useCurrentVin } from './currentVin.hook'
import { useDeepCompareEffect, useMemoizedFn, useUnmount } from 'ahooks'
import { DrivingMode } from '../api/vehicle.api'
import { TrafficLightColor } from '../components/TrafficLight'

export enum REMOTE_COMMAND_TYPE {
    SUPERVISOR_ALIVE = 0,
    PNC_BYPASS_ENABLE = 1,
    SAFETY_BRAKE_ENABLE = 2,
    REMOTE_VIDEO_TOGGLE = 3,
    OFFLINE_CHECK_ENABLE = 4,
    SAFETY_STOP_CAR = 5,
    SAFETY_SLOW_DOWN = 6,
    AIR_KNIFE = 7,
    SET_SLIPPERY_MODE = 8,
    SET_MAX_SPEED_MPS = 9,
    AFTER_COLLISION_VERIFY = 10,
    MOCK_TRAFFIC_LIGHT = 12,
    ROUTER_REDIAL = 13,
    ROUTER_SWITCH = 14,
    ROUTER_REBOOT = 15,
    PARKING = 17
}

export interface IRemoteCommandBase {
    cmd_type: REMOTE_COMMAND_TYPE;
    cmd: any;
    auth?: string;
}

export enum IVehicleOpenStatus {
    CLOSE = 0,
    OPEN = 1,
    OPENING = 11,
    CLOSING = 12,
}

export const VehicleOpenStatusMap = {
    [IVehicleOpenStatus.CLOSE]: '已关机',
    [IVehicleOpenStatus.OPEN]: '已开机',
    [IVehicleOpenStatus.OPENING]: '开机中',
    [IVehicleOpenStatus.CLOSING]: '关机中'
}

export enum IVehicleWorkingState {
    REST = 0,
    WORK = 1,
    DELIVERY = 4,
    RETURN = 5
}

export const VehicleWorkingStateMap = {
    [IVehicleWorkingState.REST]: '休息中',
    [IVehicleWorkingState.WORK]: '空闲中',
    [IVehicleWorkingState.DELIVERY]: '配送中',
    [IVehicleWorkingState.RETURN]: '返程中',
}

const getTopic = (vin: string) => {
    return {
        CONTROL_STATE: `remote_control_state_${vin}`,
        QTG_MONITOR: `qtg_monitor_${vin}`,
        REMOTE_COMMAND: `remote_safety_cmd_${vin}`,
    }
}
const entryPoint = process.env.REACT_APP_MQTT_ENTRY_POINT || '';

export const [
    MqttProvider,
    useMqttRemoteControlState,
    useMqttQtgMonitor,
    useMqttSendMessage,
] = constate(() => {
    const { vinStr } = useCurrentVin()
    const [remoteControlState, setRemoteControlState] = useState<IControlState>()
    const [qtgMonitor, setQtgMonitor] = useState<IQtgMonitor>()

    const TOPICS = useMemo(() => getTopic(vinStr!), [vinStr])

    const onMessage = useMemoizedFn((type: string, payload: string) => {
        const str = payload.toString();
        switch (type) {
            case TOPICS.CONTROL_STATE:
                const obj = JSON.parse(str)
                if (obj && obj.source === 1 && obj.driving_mode === DrivingMode.REMOTE_ENGAGE) {
                    obj.driving_mode = DrivingMode.REMOTE_BY_MOBILE;
                }
                setRemoteControlState(obj)
                break;
            case TOPICS.QTG_MONITOR:
                const qtg = JSON.parse(str)
                setQtgMonitor(qtg)
                break;
        }
    })

    const onConnect = useMemoizedFn(() => {
        client.subscribe(TOPICS.CONTROL_STATE)
        client.subscribe(TOPICS.QTG_MONITOR)
    })

    const [client] = useState(() => {
        const rEntryPoint = window.location.protocol === 'https:' ? entryPoint.replace('ws://', 'wss://') : entryPoint.replace('wss://', 'ws://')
        const mqttclient = mqtt.connect(rEntryPoint, {
            connectTimeout: 4000,
            keepalive: 60,
            clientId: 'mqttjs_badass_index_' + Math.random().toString(16).substr(2, 8)
        })
        mqttclient.on('connect', onConnect)
        mqttclient.on('message', onMessage)
        return mqttclient
    })

    useDeepCompareEffect(() => {
        client.subscribe(TOPICS.CONTROL_STATE)
        client.subscribe(TOPICS.QTG_MONITOR)
        return () => {
            client.unsubscribe(TOPICS.CONTROL_STATE)
            client.unsubscribe(TOPICS.QTG_MONITOR)
        }
    }, [client, TOPICS])

    const onDestoryAll = useMemoizedFn(() => {
        client.off('connect', onConnect)
        client.off('message', onMessage)
    })

    useUnmount(onDestoryAll)

    const sendMessage = useMemoizedFn((command: IRemoteCommandBase) => {
        if (!client) {
            throw new Error("mqtt client 不应为null");
        }
        client.publish(
            TOPICS.REMOTE_COMMAND,
            JSON.stringify({ auth: 'web_v1', ...command, time: Date.now() })
        )
    })
    return {
        remoteControlState,
        qtgMonitor,
        sendMessage,
    }
},
    state => state.remoteControlState,
    state => state.qtgMonitor,
    state => state.sendMessage,
)

interface IControlState {
    remote_client_id: string;
    battery_percent?: number;
    speed_mps?: number;
    driving_mode: DrivingMode;
    source?: number;
}

interface IQtgMonitor {
    tlLeft: TrafficLightColor,
    tlForward: TrafficLightColor,
    tlRight: TrafficLightColor
}