import { useSpeed } from '../../hooks/classic.hook/speed.hook'
import css from './index.module.scss'

export const Speed = () => {
    const value = useSpeed()
    
    return <div className={css['speed']}>
        <div className={css['label']}>车速</div>
        <div>
            <span className={css['icon']}></span>
            <span className={css['ccyan']}>{value?.toFixed(0)}km/h</span>
        </div>
    </div>
}