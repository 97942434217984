import axios from "axios";
import cookie from 'js-cookie'

export interface IInnerShop { innerShopName: string; innerShopId: number }

export const getCurrentUser = () => {
    const uid = cookie.get('uid') || 0
    const token = cookie.get('token') || ''
    return axios.get<{ code: number, msg?: string, data?: { user: { name: string; id: string }; innerShopList?: IInnerShop[]; } }>(`/moria-api/monitorWeb/getUserInfo`, {
        headers: {
            uid, token
        }
    }).then(d => {
        return d.data
    })
}

export const logout = () => {
    return axios.post('/moria-api/login/logout')
}


