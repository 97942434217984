import axios from "axios";

interface IGetVerifyCodeData { "bizId"?: string; };
export const getVerifyCodeApi = (mobile: string) =>  {
    return axios.post<{code: number, msg?: string, data?: IGetVerifyCodeData}>(`/moria-api/login/get_verify_code_with_phone?mobile=${mobile}`, {}, {
        headers: {
            app_name: 'rhino_web_monitor'
        }
    }).then(d => {
        return d.data
    })
}

interface ILoginData { "userInfo"?: { "bindCommunities"?: (string)[]; "doingOrderCount"?: number; "id"?: number; "isInReview"?: boolean; "location"?: { "lat"?: number; "lng"?: number; }; "maxOrderCount"?: number; "maxTransferCount"?: number; "mobile"?: string; "name"?: string; "shopId"?: number; "shopName"?: string; "token"?: string; "totalOrderCount"?: number; "transferCount"?: number; "userType"?: number; "waitingEndTime"?: number; "workingState"?: number; "workingStateTime"?: number; }; }
export const loginApi = (bizId: string, mobile: string, code: string) =>  {
    return axios.post<{code: number, msg?: string, data?: ILoginData}>(`/moria-api/login/login_with_verify_code?bizId=${bizId}&mobile=${mobile}&code=${code}`, {}, {
        headers: {
            app_name: 'rhino_web_monitor'
        }
    }).then(d => {
        return d.data
    })
}