import { useCallback, useRef, useState } from "react"
import cookies from 'js-cookie'
import { getVerifyCodeApi, loginApi } from "../../api/login.api";
import { useNavigate } from "react-router-dom";

(window as any).cookies = cookies

export const useLogin = () => {
    const navigate = useNavigate();
    const [limit, setLimit] = useState(0)
    const [bizId, setBizId] = useState('')
    const [, setPhone] = useState('')
    const [loginError, setLoginError] = useState('')

    const updateLimit = useCallback(() => {
        if (limit > 0) {
            setLimit(limit - 1)
        }
    }, [limit, setLimit])
    const updateLimitRef = useRef(updateLimit)
    updateLimitRef.current = updateLimit

    const sendVerifyCode = useCallback((mobile: string) => {
        setLimit(60)
        setPhone(mobile)
        getVerifyCodeApi(mobile).then(d => {
            setBizId(d.data?.bizId!)
        })
    }, [setLimit, setBizId, setPhone])

    const login = useCallback((verfiyCode: string, phone: string) => {
        loginApi(encodeURIComponent(bizId), phone, verfiyCode).then(d => {
            if (d.code !== 0) {
                setLoginError(d.msg!);
            } else {
                cookies.set("token", d.data?.userInfo?.token!, { expires: 30 })
                cookies.set("uid", '' + d.data?.userInfo?.id!, { expires: 30 })
                navigate('/');
            }
        }).catch(e => {
            setLoginError(e.message)
        })
    }, [bizId, navigate])

    useState(() => {
        setInterval(() => {
            updateLimitRef.current()
        }, 1000)
    })

    return {
        limit,
        sendVerifyCode,
        login,
        loginError
    }
}