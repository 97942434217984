import { Client, generateToken } from 'urtc-sdk'

const urtcAppId = 'urtc-smxi45rf'
const urtcAppKey = '5de16cedc524e935237c6efcdc81409d'

export enum CameraType {
    FRONT = 'camera_front',
    USB_LEFT = 'camera_usb_left',
    REAR = 'camera_rear',
    USB_RIGHT = 'camera_usb_right',
    LEFT = 'camera_left',
    RIGHT = 'camera_right',
    MICRO_PHONE = 'vehicle_microphone',
    TRAFFIC_LIGHT = 'camera_front_6mm',
    TRAFFIC_LIGHT_12MM = 'camera_front_12mm',

    frontface_right = 'frontface_right',
    rightside_front = 'rightside_front',
    rearhead_middle = 'rearhead_middle',
    leftside_back = 'leftside_back',
    frontface_middle = 'frontface_middle',
    rightside_back = 'rightside_back',
    leftside_front = 'leftside_front',
    frontface_left = 'frontface_left',

    camera_left_back = 'camera_left_back',
    camera_right_back = 'camera_right_back',

    frontface_right_undistorted = 'frontface_right_undistorted',
    rightside_front_undistorted = 'rightside_front_undistorted',
    rearhead_middle_undistorted = 'rearhead_middle_undistorted',
    leftside_back_undistorted = 'leftside_back_undistorted',
    frontface_middle_undistorted = 'frontface_middle_undistorted',
    rightside_back_undistorted = 'rightside_back_undistorted',
    leftside_front_undistorted = 'leftside_front_undistorted',
    frontface_left_undistorted = 'frontface_left_undistorted',

    surrond_view = 'surrond_view',

    debug_1 = 'debug_1',
    debug_2 = 'debug_2',
    debug_3 = 'debug_3',
    debug_4 = 'debug_4',
    debug_5 = 'debug_5',
    debug_6 = 'debug_6',
    debug_7 = 'debug_7',
    debug_8 = 'debug_8'
}

export const initRtc = (
    vehicleIdStr: string,
) => {
    const urtcUserId = 'whr' + vehicleIdStr + '_' + (new Date()).getTime();
    const urtcRoomId = 'whr' + vehicleIdStr
    const urtcToken = generateToken(urtcAppId, urtcAppKey, urtcRoomId, urtcUserId)
    
    const client = new Client(urtcAppId, urtcToken)

    client.joinRoom(urtcRoomId, urtcUserId, () => {
        console.log('加入房间成功')
    }, () => {
        console.timeLog('加入房间失败')
    })
    return client
}

export const bindStream = (
    client: Client,
    position: CameraType,
    container: HTMLVideoElement|null,
    listener?: {
        onConnect: () => void,
        onDisConnect: () => void
    },
    vehicleIdStr?: string
) => {

    if (!container) {
        return
    }
    if (!container.attributes.getNamedItem('autoPlay')) {
        console.warn(container, '应该设置autoplay属性,否则视频可能无法正常播放')
    }

    container.srcObject = null;

    client.on('stream-added', stream => {
        if (stream.uid.indexOf(position) > -1) {
            client.subscribe(stream.sid, err => {
                console.error('failed to subscribe stream:' + stream.sid, err);
            })
        }
    })

    client.on('stream-subscribed', stream => {
        if (stream.uid.indexOf(position) > -1) {
            container.srcObject = stream.mediaStream
            listener?.onConnect()
        }
        //}
    })

    client.on('stream-removed', stream => {
        if (stream.uid.indexOf(position) > -1) {
            container.srcObject = null
            console.log('退出房间成功', vehicleIdStr, position)
            listener?.onDisConnect()
        }
    })

    client.on('stream-reconnected', stream => {
        if (stream.uid && stream.uid.indexOf(position) > -1) {
            container.srcObject = stream.mediaStream
            listener?.onConnect()
        }
    })
}

export const unbindRtc = (client: Client) => {
    client.leaveRoom(undefined, () => {
        console.log('退出房间成功')
    }, () => {
        console.log('退出房间失败')
    })
}