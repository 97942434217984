import { createPortal } from 'react-dom'
import css from './index.module.scss'
import { ReactNode, useState } from 'react'
import { Icon } from '../Widget/icon'
import constate from 'constate'
import { useMemoizedFn } from 'ahooks'

const [ModalProvider, useModalHook] = constate(() => {
    const [modal, setModal] = useState<{
        title: string;
        content: ReactNode;
        onOk: () => void;
        onCancel: () => void;
        showCancel?: boolean;
        showOk?: boolean;
    }|null>()

    return { modal, setModal }
})

const ModalWidget = () => {
    const [container, setContainer] = useState<HTMLDivElement|null>(null)
    const { modal, setModal } = useModal()

    const onCancel = useMemoizedFn(() => {
        modal?.onCancel()
        setModal(null)
    })
    const onOk = useMemoizedFn(() => {
        modal?.onOk()
        setModal(null)
    })
    return <>
        <div ref={(ref) => setContainer(ref)}></div>
        {container && modal && createPortal(<div className={css.bg}>
            <div className={css.modal}>
                <div className={css.title}>
                    { modal.title }
                    <div className={css.close} onClick={onCancel}>
                        <Icon type={'off'}></Icon>
                    </div>
                </div>
                <div className={css.content}>{modal.content}</div>
                {
                    modal?.showCancel === false && modal?.showOk === false ? '' :
                    <div className={css['btn-grp']}>
                        {
                            modal?.showCancel === false ? '' :
                            <button onClick={onCancel}>取消</button>
                        }
                        {
                            modal?.showOk === false ? '' :
                            <button className={css.primary} onClick={onOk}>确定</button>
                        }
                    </div>
                }
            </div>
        </div>, container)}
    </>
}

export const ModalAble: React.FC = ({children}) => <ModalProvider>
    <ModalWidget />
    {children}
</ModalProvider>

export const useModal = useModalHook