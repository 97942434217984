import { useCurrentVin } from '../../hooks/currentVin.hook'
import css from './index.module.scss'

export const RouterPingInfo = () => {
    const { vehicle } = useCurrentVin()

    return <div className={css['router-ping-info']}>
        <div className={css['label']}>延时</div>
        <div>
            <span className={css['icon']}></span>
            <span className={css['ccyan']}>{vehicle?.latency && vehicle?.latency > -1 ? `${vehicle.latency.toFixed(0)}ms` : '--'}</span>
        </div>
    </div>
}