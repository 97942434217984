import css from './index.module.scss'
import { CurrentUser } from '../CurrentUser'

export const Header = () => {
    
    return <div className={css.header}>
        <img alt='logo' src="./logo.svg" className={css.logo} />
        <div className={css['button-wrap']}>
            <CurrentUser />
        </div>
    </div>
}