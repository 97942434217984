import { RefObject, useRef, useState } from "react"
import { useInterval, useMemoizedFn } from 'ahooks'

export const useIsExceptionHook = (ref: RefObject<HTMLVideoElement|HTMLDivElement|null>) => {
    const [isException, setIsException] = useState(false)

    const exceptionResolveRef = useRef<() => {}>()

    const frameCount = useRef(0)

    useInterval(() => {
        if(!ref.current) return null
        let count = 0
        if (ref.current instanceof HTMLVideoElement) {
            count = (ref.current as any).webkitDecodedFrameCount
        } else {
            count = (ref.current.querySelector('video') as any)?.webkitDecodedFrameCount
        }
        
        if (count !== frameCount.current) {
            setIsException(false)
            frameCount.current = count

            exceptionResolveRef.current?.()
            exceptionResolveRef.current = undefined
        } else {
            setIsException(true)
        }
    }, 200)

    const onFirstExceptionResolve = useMemoizedFn((cb) => {
        exceptionResolveRef.current = cb
    })

    return { isException, onFirstExceptionResolve }
}