import { getVehicleList } from '../api/vehicle.api'
import constate from 'constate'
import { useRequest } from 'ahooks'
import { map } from "lodash"
import { useGlobalScope } from './globalScope.hook'

export const [VehicleListProvider, useVehicleList] = constate(() => {
    const { innerShop } = useGlobalScope();
    const { data, loading } = useRequest(() => getVehicleList(innerShop?.innerShopId), { pollingInterval: 5000, refreshDeps: [innerShop?.innerShopId] });
    return {
        vehicles: data?.vehicles,
        loading,
        vehicleIds: map(data?.vehicles || [], 'id')
    };
})