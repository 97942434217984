import { Col, Row, Skeleton } from 'antd'
import css from './index.module.scss'
import { useVehicleList } from '../../hooks/vehicleList.hook'
import { IVehicle } from '../../api/vehicle.api';
import { useCurrentVin } from '../../hooks/currentVin.hook';
import { IVehicleWorkingState, VehicleOpenStatusMap } from '../../hooks/mqtt.hook';
import { Battery } from '../Widget/battery';
import { VehicleState } from '../Widget/state';
import { useEffect, useMemo, useState } from 'react';

export const VehicleGroup = () => {
    const { vehicleId, switchVehicle } = useCurrentVin();
    const { vehicles, loading } = useVehicleList();
    const placeholders: IVehicle[] = [{ id: -1 }, { id: -2 }];
    const [isFirsLoading, setIsFirsLoading] = useState(true);
    useEffect(() => {
        if (loading === false && isFirsLoading) {
            setIsFirsLoading(false);
        }
    }, [loading, isFirsLoading])
    const showLoading = useMemo(() => {
        return loading && isFirsLoading;
    }, [loading, isFirsLoading])
    return <>
        {
            vehicles && vehicles.length === 0 ? <div className={css['no-vehicle-wrap']}><div className={css['no-vehicle']}>暂无运营车辆</div></div>
                :
                (showLoading ? placeholders : (vehicles || [])).map((item) => {
                    const isCurrent = item.id === vehicleId;
                    const isRest = item.workingState === IVehicleWorkingState.REST;
                    return <Row className={`${css['vehicle-item']} ${isCurrent ? css['vehicle-item-active'] : ''} ${isRest ? css['vehicle-item-rest'] : ''}`} key={item.id}
                        onClick={() => {
                            if (item.id > -1) {
                                switchVehicle(item.id);
                            }
                        }}
                    >
                        <Skeleton active
                            paragraph={{
                                rows: 1,
                                width: '100%'
                            }}
                            loading={showLoading}
                        >
                            <Col span={12}>
                                <div className={css['vehicle-item-name']}>{item?.name}</div>
                                <div className={css['vehicle-item-power']}>{VehicleOpenStatusMap[item.openStatus!]}</div>
                            </Col>
                            <Col span={12} className={css['vehicle-item-right']}>
                                <div className={css['vehicle-item-state']}><VehicleState value={item.workingState!} /></div>
                                {
                                    item.battery ?
                                    <div className={css['vehicle-item-battery']}><Battery value={item.battery} chargeStatus={item.chargeStatus}></Battery></div>
                                    : ''
                                }
                            </Col>
                        </Skeleton>
                    </Row>
                })
        }
    </>
}