import { useMemo, useState } from 'react'
import css from './index.module.scss'
import dayjs from 'dayjs'
import { Icon } from '../Widget/icon'
import { useCurrentVin } from '../../hooks/currentVin.hook'

interface IOrder {
    title: string
    receiver_address: string
    stationIndex: number
    station: { name: string, cnt?: number, autoRtnTime?: number }
}

const Select: React.FC<{ down: boolean }> = ({ children, down }) => <div className={`${css.select} ${down ? css.down : ''}`}>{children}</div>

const Order: React.FC<{ order: IOrder, isActive: boolean, station?: { name: string, cnt?: number, autoRtnTime?: number } }> = ({ order, isActive, station }) => {

    return <div className={`${css['order-item']} ${css['order-item-travelplan']} ${isActive ? css['active'] : ''}`}>
        <div>
            <span className={css['icon-zhandian']}><Icon type="zhandian"></Icon></span>
            <span className={css.title}>第 {order.stationIndex} 站</span>
            {station?.name}
        </div>
        <div>
            <span className={css['icon-baoguo']}><Icon type="baoguo"></Icon></span>
            <span className={css.title}>订单数</span>
            <span className={css.title}>{(station?.cnt || station?.cnt === 0) ? station.cnt : '--'}</span>
        </div>
        {station?.autoRtnTime && <span className={css['left-time']}>定时返程 <span className={css['ccyan']}>{dayjs(station.autoRtnTime * 1000).format('HH:mm')}</span></span>}
    </div>
}

export const OrderPanel = () => {
    const [showOrderList, setShowOrderList] = useState(false)

    const { travelStations, currentTarget, createTime } = useCurrentVin()

    const useSetTravelPlanOrders = useMemo(() => {
        const orders = travelStations?.map((station, idx) => ({
            title: `第${idx + 1}个站点, 共${station.cnt}个包裹`,
            stationIndex: idx + 1,
            receiver_address: station.name,
            station: station
        })) ?? []
        return orders
    }, [travelStations])

    const orderCurrentTargetInfo = useMemo(() => {
        const isArrived = currentTarget?.isArrived;
        const isShowAutoRtnTime = isArrived && currentTarget?.autoRtnTime;
        const isShowEstimateArriveTime = !isArrived && currentTarget?.estimateArriveTime;
        const isShowDestToDestination = !isArrived && currentTarget?.destToDestination;
        const isShowUnderway = isShowEstimateArriveTime || isShowDestToDestination;
        return {
            isArrived,
            isShowAutoRtnTime,
            isShowEstimateArriveTime,
            isShowDestToDestination,
            isShowUnderway,
            isShowExtraInfo: isShowAutoRtnTime || isShowUnderway
        }
    }, [currentTarget])

    return <div className={css['order-panel']}>
        <div className={css.order} onClick={() => setShowOrderList(!showOrderList)}>
            <div className={css.label}>行程信息</div>
            <div className={css['icon-line']}>|</div>
            <Select down={showOrderList}><div className={css.current}>
                {
                    currentTarget &&
                    <div className={orderCurrentTargetInfo.isShowExtraInfo ? css['oneline'] : ''}>
                        <span className={`${css['icon']} ${css['icon-my-vehicle']}`}><Icon type="my-vehicle"></Icon></span>
                        <span className={`${css['text']} ${css.status}`}>
                            {currentTarget?.isArrived ? '已到站' : '进行中'}
                        </span>
                        <span className={css['text']}>{currentTarget.name}</span>
                    </div>
                }
                {
                    orderCurrentTargetInfo.isShowExtraInfo && (
                        orderCurrentTargetInfo.isShowAutoRtnTime ?
                            <div><span className={css['icon']}><Icon type="shijian"></Icon></span><span className={css['text']}>定时返程 <span className={css['ccyan']}>{currentTarget?.autoRtnTime && dayjs(currentTarget.autoRtnTime * 1000).format('HH:mm')}</span></span></div>
                            :
                            orderCurrentTargetInfo.isShowUnderway && <div>
                                <span className={css['icon']}><Icon type="shijian"></Icon></span>
                                <span className={css['text']}>
                                    {orderCurrentTargetInfo.isShowEstimateArriveTime && <>预计<span className={css['ccyan']}>{currentTarget?.estimateArriveTime && dayjs(currentTarget.estimateArriveTime * 1000).format('HH:mm')}</span>到达 |</>}
                                    {orderCurrentTargetInfo.isShowDestToDestination && <> 剩余 <span className={css['ccyan']}>{currentTarget?.destToDestination}m</span></>}
                                </span>
                            </div>
                    )
                }
                {
                    !currentTarget && !orderCurrentTargetInfo.isShowExtraInfo && <div>--</div>
                }
            </div></Select>
            {showOrderList && <div className={css.mask}></div>}
            {showOrderList && <div className={css['list']}>
                {createTime &&
                    <div className={`${css['order-item']} ${css['order-item-travelplan']}`}>
                        <span className={css['mr12']}>创建任务</span>
                        {dayjs(createTime * 1000).format('YYYY-MM-DD HH:mm:ss')}
                    </div>}
                {useSetTravelPlanOrders.map((order, key) => <Order key={key} order={order} isActive={false} station={order.station} />)}
                {
                    !createTime && useSetTravelPlanOrders.length === 0 &&
                    <div className={`${css['order-item']} ${css['order-item-travelplan']}`}>
                        暂无行程信息
                    </div>
                }
            </div>}
        </div>
    </div>
}