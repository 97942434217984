import { Banner } from '../../components/Banner'
import { CameraGrp } from '../../components/Camera'
import { OrderPanel } from '../../components/OrderPanel'
import { WithVin } from '../../components/WithVin'
import { Map } from '../../components/Map'
import { MqttProvider } from '../../hooks/mqtt.hook'
import css from './index.module.scss'
import { MapProvider } from '../../hooks/map.hook'
import { TrafficLightCameraProvider } from '../../hooks/camera.hook/trafficLightCamera.hook'
import { useCurrentVin } from '../../hooks/currentVin.hook'
import { SurrondViewCameraProvider } from '../../hooks/camera.hook/surrondViewCamera.hook'
import { useGlobalScope } from '../../hooks/globalScope.hook'

const App = () => {
    const { containerId } = useGlobalScope()
    const { vehicle } = useCurrentVin()
    return <div className={css['select-page']} id={containerId}>
        <div className={css['vin-control']}>
            <div className={css['vin-left']}>
                <div className={css.map} style={{ zIndex: vehicle ? 0 : -1 }}>
                    <Map />
                </div>
                <WithVin>
                    <OrderPanel />
                </WithVin>
            </div>
            <div className={css['vin-right']}>
                <WithVin>
                    <Banner />
                    <div className={css['camera-grp']}>
                        <CameraGrp />
                    </div>
                </WithVin>
            </div>
        </div>
    </div>
}

const Page = () => <MqttProvider>
    <MapProvider>
        <TrafficLightCameraProvider>
            <SurrondViewCameraProvider>
                <App />
            </SurrondViewCameraProvider>
        </TrafficLightCameraProvider>
    </MapProvider>
</MqttProvider>

export default Page;