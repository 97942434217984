import css from './index.module.scss';
import VehicleDetail from '../Page';
import { CurrentVinProvider } from '../../hooks/currentVin.hook';
import { UserProvider } from '../../hooks/user.hook';
import { GlobalScopeProvider, useGlobalScope } from '../../hooks/globalScope.hook';
import { ModalAble } from '../../components/Modal';
import { VehicleListProvider } from '../../hooks/vehicleList.hook';
import { Header } from '../../components/Header';
import { VehicleGroup } from '../../components/VehicleGroup';
import { InnerShopSwitch } from '../../components/InnerShopSwitch';

const MultiVehicleContent = () => {
    const { contextHolder } = useGlobalScope()
    return <>
        {contextHolder}
        <ModalAble>
            <Header />
            <div className={css['page-wrap']}>
                <div className={css['page-left']}>
                    <InnerShopSwitch />
                    <div className={css['vehicle-group']}>
                        <VehicleGroup />
                    </div>
                </div>
                <div className={css['page-right']}>
                    <VehicleDetail />
                </div>
            </div>
        </ModalAble>
    </>
}
const MultiVehicle = () => {
    return <UserProvider>
        <GlobalScopeProvider>
            <VehicleListProvider>
                <CurrentVinProvider>
                    <MultiVehicleContent />
                </CurrentVinProvider>
            </VehicleListProvider>
        </GlobalScopeProvider>
    </UserProvider>
}

export default MultiVehicle;