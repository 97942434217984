import { useMemo } from "react"
import { Icon } from "./icon"
import { DeviceStatus } from "../../api/vehicle.api"
import css from './battery.module.scss'

export const Battery = ({value, powerStatus, chargeStatus}: {value: number, powerStatus?: DeviceStatus, chargeStatus?: number}) => {
    const style = useMemo(() => {
        if (powerStatus === DeviceStatus.POWER_OFF) return { icon: 'power-off', color: 'rgb(140, 148, 159)', text:'已关机' }
        if (chargeStatus === 1) return {icon: 'chongdianzhong', color: '#0EF2BA'}
        if (value >= 70) return {icon: 'dianliang-lvse', color: '#0EF2BA'}
        if (value >= 30) return {icon: 'dianliang-chengse', color: '#F99609'}
        return { icon: 'dianliang-hongse', color: 'red' }
    }, [value, powerStatus, chargeStatus])
    return <div style={{display: 'flex', alignItems: 'center'}}>
        <span className={css['battery-icon']}><Icon type={style.icon}></Icon></span>
        <span className={css['battery-text']} style={{color: style.color}}>{style.text ?? `${value}%`}</span>
    </div>
}