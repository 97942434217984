import { IVehicleWorkingState, VehicleWorkingStateMap } from "../../hooks/mqtt.hook";
import { Icon } from "./icon";
import css from './state.module.scss'

const stateMap: {
    [key in IVehicleWorkingState]?: {icon: string, color: string}
} = {
    [IVehicleWorkingState.REST]: { icon: 'resting', color: '#8C949F' },
    [IVehicleWorkingState.WORK]: { icon: 'working', color: '#14E034 '},
    [IVehicleWorkingState.DELIVERY]: {icon: 'in-delivery', color: '#0EF2F5'},
    [IVehicleWorkingState.RETURN]: { icon: 'on-the-return-journey', color: '#FF7800' },
}

export const VehicleState = ({value}: {value: IVehicleWorkingState}) => <>
    <span className={css['state-icon']}><Icon type={stateMap[value]?.icon ?? 'my-vehicle'}></Icon></span><span className={css['state-text']} style={{color:stateMap[value]?.color}}>{VehicleWorkingStateMap[value]}</span>
</>