import { useCurrentUser } from "../../hooks/user.hook"
import css from './index.module.scss'

export const CurrentUser = () => {
    const { user, logout } = useCurrentUser()

    if (!user) {
        return null;
    }
    return <div className={css['current-user']}>{user?.name || ''}<span className={css.logout} onClick={logout}>退出登录</span></div>
}