import constate from "constate"
import { useEffect, useRef } from "react"
import { useDestination } from "./destination"
import { useLocation } from "./location/location.hook"
import { useRoutePath } from "./routePath"

const useMapInside = () => {
    const mapContainer = useRef(null)

    const gdmap = useRef<any>(null)

    useEffect(() => {
        if (!mapContainer.current) {
            return
        }
        if (gdmap.current) {
            gdmap.current.destroy();
            gdmap.current = null;
        }
        gdmap.current = new AMap.Map(mapContainer.current!, {
            resizeEnable: true,
            zoom: 17.5,
            pitch: 55.94919957310569,
            rotation: -0.7908261543741522,
            //mapStyle: 'amap://styles/grey',
            buildingAnimation: false, //楼块出现是否带动画
            mapStyle: "amap://styles/blue"
        } as any);

        return () => {
            if (gdmap.current) {
                gdmap.current.destroy();
                gdmap.current = null;
            }
        }
    }, []);

    useLocation(gdmap)
    useDestination(gdmap)
    useRoutePath(gdmap)
    
    return {
        mapContainer
    }
}

export const [MapProvider, useMap] = constate(useMapInside)
