import constate from "constate";
import { useState } from "react";
import { useCurrentVin } from "../currentVin.hook";
import { useDeepCompareEffect } from "ahooks";
import { CameraType } from "../../utils/bind-rtc";

export const [TrafficLightCameraProvider, useTrafficLightCamera] = constate(() => {
    const [visible, setVisible] = useState(false)
    const [currentType, setCurrentType] = useState<CameraType>(CameraType.TRAFFIC_LIGHT);
    const { vehicleId } = useCurrentVin()

    useDeepCompareEffect(() => {
        setVisible(false)
        setCurrentType(CameraType.TRAFFIC_LIGHT)
    }, [vehicleId])

    return { visible, setVisible, currentType, setCurrentType }
})