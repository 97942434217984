import { Route, Routes, HashRouter } from 'react-router-dom';
import App from './page/App';
import { Login } from './page/login';

const AppRouter = () => {
  return <HashRouter>
    <Routes>
      <Route path="/" element={<App></App>}></Route>
      <Route path="/login" element={<Login />}></Route>
    </Routes>
  </HashRouter>
}



export default AppRouter;