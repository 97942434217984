import { useDeepCompareEffect } from "ahooks"
import { RefObject } from "react"
import { IAMap } from "../amap.interface"
import end from './end.png'
import { useCurrentVin } from "../../currentVin.hook"

const makePoint = (position: [number, number], title: string) => new AMap.Marker({
    icon: end,
    position,
    label: {
        direction: 'top',
        content: title,
        offset: new AMap.Pixel(0, -3),
    },
    offset: new AMap.Pixel(-10, -20),
    scale: 1,
    zIndex: 100
})

export const useDestination = (gdMap: RefObject<IAMap>) => {
    const { points, currentTarget } = useCurrentVin()
    const destionation = points && points[points.length - 1] ? points[points.length - 1][points[points.length - 1].length - 1] : undefined;

    useDeepCompareEffect(() => {
        let point: AMap.Marker | undefined;
        if (destionation?.lng && destionation?.lat) {
            const pos: [number, number] = [destionation.lng, destionation.lat]
            point = makePoint(pos, currentTarget?.name || '')
            gdMap.current?.add(point)
        }
        return () => {
            if (point) {
                try {
                    gdMap.current?.remove(point)
                    point = undefined;
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }, [destionation?.lng, destionation?.lat, currentTarget?.name])
}