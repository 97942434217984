import UBoxAuto from 'ubox-auto-sdk-web'
import { CameraType } from './bind-rtc';

export type Client = ReturnType<typeof initRtc>

const initRtc = () => {
// 实例化调用端
    const client = UBoxAuto({
        publicKey: 'Iczcn7x2MxnDBtApVKEn286F8iR1SmgGYrHES1em1',
        privateKey: 'D11M22lDOyQJKO4cDE4Xpg8vjEe6R67hnG3SvyJtpG8j6rF7YLiQlz4HvV2L4DyNig', // 在 API 密钥中查看管理自己的密钥： https://console.ucloud.cn/uaccount/api_manage
        projectId: 'org-rp1rwy' // 在项目管理中查看需要管理的项目 id： https://console.ucloud.cn/uaccount/iam/project_manage
    }, { debug: true });

    return client
}

const client = initRtc()

const idMapper: {[key in CameraType]?: string} = {
    [CameraType.FRONT]: '2',
    [CameraType.REAR]: '3',
    [CameraType.LEFT]: '4',
    [CameraType.RIGHT]: '5',
    [CameraType.USB_LEFT]: '/dev/video0',
    [CameraType.USB_RIGHT]: '/dev/video2',
    [CameraType.MICRO_PHONE]: '',
    [CameraType.TRAFFIC_LIGHT]: '',
    [CameraType.TRAFFIC_LIGHT_12MM]: ''
}

const boxs = client.getSdnboxCameraList()

export const getCamera = (vehicleId: string, type: CameraType) => {
    return boxs.then(
        d => d.find((camera: { Name: string | string[]; }) => camera.Name.indexOf(vehicleId) > -1)
    ).then(box => {
        const camera = box.Camera.filter((item: any) => {
            return item.Name === idMapper[type]
        })?.[0]
        if (!camera) {
            debugger
        }
        return {box, camera}
    })
}

const getToken = (vehicleId: string, type: CameraType) => {
    return getCamera(vehicleId, type).then(({box, camera}) => {
        return {
            cameraId: camera.ID,
            sdnboxId: box.ID
        }
    })
}

export const bindStream = (
    vehicleId: string,
    position: CameraType,
    container: HTMLDivElement|null,
    listener?: {
        onConnect: () => void,
        onDisConnect: () => void
    }
) => {
    return getToken(vehicleId, position).then(argv => {
        return client.play(container!, argv as any).start()
    })
}