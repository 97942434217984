import { useCreation, useDeepCompareEffect, useThrottleFn } from "ahooks"
import { RefObject } from "react"
import { IAMap } from "../amap.interface";
import { useCurrentVin } from "../../currentVin.hook";

const DEFAULT_POS: [number, number] = [114.34215626258452, 22.70764768361502]

export const useLocation = (gdMap: RefObject<IAMap>) => {
    const { vehicle } = useCurrentVin()
    const location = vehicle?.location

    const marker = useCreation(() => {
        return new AMap.Marker({
            content: '<div class="map-location-gif"><div class="map-location-gif-redcircle"></div><div class="map-location-gif-whitecircle"></div><div class="map-location-gif-center"></div></div>',
            position: DEFAULT_POS,
            scale: 1
        })
    }, [])

    const { run: reCenter } = useThrottleFn((pos: [number, number]) => {
        gdMap.current?.setCenter(pos)
    }, { wait: 3000 })

    useDeepCompareEffect(() => {
        if (location?.lng && location?.lat) {
            const pos: [number, number] = [location?.lng!, location?.lat!]
            gdMap.current?.add(marker)
            marker.setPosition(pos)
            reCenter(pos)
        }
        return () => {
            gdMap.current?.remove(marker)
        }
    }, [location?.lng, location?.lat])

}