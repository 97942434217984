import { useCookieState, useMemoizedFn, useRequest } from 'ahooks'
import constate from 'constate'
import { getCurrentUser, logout } from '../api/user.api'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { notification } from 'antd'
import { map } from "lodash"

const useLogin = !(window.location.search.indexOf('useLogin=testmode') > -1)

export const [UserProvider, useCurrentUser] = constate(() => {
    const [, setToken] = useCookieState('token')
    const [, setUid] = useCookieState('uid')
    const navigate = useNavigate();
    const reLgoin = useMemoizedFn(() => {
        navigate('/login')
    })
    const { data: info, run } = useRequest(() => getCurrentUser().then((d) => {
        if (d.code !== 0) {
            if (d.code !== 1001) {
                notification.error({
                    message: `身份验证错误：${d.code}-${d.msg || '系统错误'}`
                })
            }
            if (d.code < 2000) {
                reLgoin()
            }
        }
        return d
    }).catch(() => {
        reLgoin()
    }), { manual: true })
    useEffect(() => {
        if (useLogin) {
            run()
        }
    }, [run])
    const logoutF = useMemoizedFn(() => {
        logout().then(() => {
            setToken('')
            setUid('')
            reLgoin()
        })
    })
    return {
        user: info?.data?.user,
        innerShopList: info?.data?.innerShopList,
        innerShopIds: map(info?.data?.innerShopList || [], 'innerShopId'),
        logout: logoutF
    }
})