import { useEffect, useMemo } from 'react'
import { BrtcClientProvider, useBrtcCamera } from '../../hooks/camera.hook/brtc.hook'
import { CameraType } from '../../utils/bind-rtc'
import '../../utils/bind-rtc-ubox-new'
import css from './index.module.scss'
import { BrtcLoggerProvider } from '../../hooks/logger.hook/brtcLogger.hook'
import { useCurrentVin } from '../../hooks/currentVin.hook'
import { IVehicleOpenStatus } from '../../hooks/mqtt.hook'

const CameraTitle: { [key in CameraType]?: string } = {
    [CameraType.FRONT]: '正前视',
    [CameraType.LEFT]: '左前视',
    [CameraType.RIGHT]: '右前视',
    [CameraType.USB_LEFT]: '正左视',
    [CameraType.USB_RIGHT]: '正右视',
    [CameraType.REAR]: '正后视',
    [CameraType.MICRO_PHONE]: '',
    [CameraType.TRAFFIC_LIGHT]: '红绿灯6mm',
    [CameraType.TRAFFIC_LIGHT_12MM]: '红绿灯12mm',
}

export const BrtcCamera: React.FC<{ type?: CameraType, isMirror?: boolean, hash?: string }> = ({ type = CameraType.FRONT, isMirror, hash }) => {
    const { ref, toggleBtn, status, isLoading, isException, containerId, info } = useBrtcCamera(type, hash)
    const isMirrorT = isMirror && info?.rearFlip;
    const { vehicle } = useCurrentVin();

    useEffect(() => {
        if (type === CameraType.TRAFFIC_LIGHT || type === CameraType.TRAFFIC_LIGHT_12MM) {
            toggleBtn()
        }
    }, [type])
    return <div className={`${css.camera}`} onClick={toggleBtn}>
        <div className={css.video} ref={ref} id={containerId} style={{ transform: isMirrorT ? `rotateY(180deg)` : '', visibility: status ? 'visible' : 'hidden' }}></div>
        {
            vehicle?.openStatus === IVehicleOpenStatus.CLOSE ? <div className={css['camera-vehicle-close']}></div> :
                (
                    status
                        ? isException ? <div className={css['camera-exception']}></div> : null
                        : <div className={css.unable}>{isLoading ? '' : ''}</div>
                )
        }
        <span className={css['camera-title']}>{CameraTitle[type] ?? type}</span>
        {/* {status ? <div className={css['stop-btn']}></div> : <div className={css['play-btn']}></div>} */}
    </div>
}

export const CameraGrp = () => {
    const Camera = useMemo(() => {
        return BrtcCamera
    }, [])

    const ClientProvider = useMemo(() => {
        return BrtcClientProvider
    }, [])

    return <div className={css['camera-grp']}>
        <ClientProvider>
            <BrtcLoggerProvider>
                <Camera type={CameraType.FRONT} />
                <Camera type={CameraType.LEFT} />
                <Camera type={CameraType.REAR} isMirror={true} />
                <Camera type={CameraType.RIGHT} />
            </BrtcLoggerProvider>
        </ClientProvider>
    </div>
}