import { useInterval, useMap, useMemoizedFn } from "ahooks"
import { getSignature } from "../../utils/signature"
import constate from "constate"
import { useBrtcClient } from "../camera.hook/brtc.hook"
import { logger as logstashLogger } from './logstash'

const testUser = true
const metric = getSignature()

export type LogInfo = {
    roomname: string,
    userid: string
} 

export const [BrtcLoggerProvider, useBrtcLogger] = constate(() => {
    const {info: logInfo} = useBrtcClient()
    const [ map, { set, remove } ] = useMap<string, boolean>()

    const start = useMemoizedFn((name: string) => set(name, true))

    const stop = useMemoizedFn((name: string) => remove(name))

    useInterval(() => {
        let displays: string[] = []
        map.forEach((v, k) => {
            if (!v) return;
            if (testUser) return;
            displays.push(k)
        })
        if (displays.length > 0) {
            const body = {
                type: 'brtc_alive',
                __time_nano__: +new Date(),
                log_id: getSignature(),
                parameter: {
                    metric,
                    roomname: logInfo?.roomname,
                    userid: logInfo.userid,
                    display: displays.join(','),
                    webVersion: '1v12'
                }
            }
            logstashLogger.trace(body)
            console.log('log brtc_alive', body)
        }
    }, 5000, { immediate: true })

    return { start, stop }
})