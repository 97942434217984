import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import './index.scss';
//import './utils/check-zoom';

import reportWebVitals from './reportWebVitals';

Sentry.init({dsn: "http://f071ac553f124a5aa16f4cc77e0a3f75@47.116.77.76:9000/2"});

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
