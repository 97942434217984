import { IVehicleOpenStatus, IVehicleWorkingState } from "../hooks/mqtt.hook";
import { request } from "../request";

export enum DrivingMode {
    READY = 0,  // ready to complete auto drive
    COMPLETE_AUTO_DRIVE = 1,
    AUTO_STEER_ONLY = 2,  // only steer
    AUTO_SPEED_ONLY = 3,  // include throttle and brake

    // security mode when manual intervention happens, only response status
    COMPLETE_MANUAL = 4,
    REMOTE_ENGAGE = 5,
    SAFETY_MODE = 6,
    EMERGENCY_MODE = 7,
    REMOTE_BY_MOBILE = 8
}

export interface IVehicle {
    alertMsg?: string;
    battery?: number;
    bleName?: string;
    cabinetLocked?: boolean;
    chargeStatus?: number;
    currentAtStation?: string;
    currentTarget?: string;
    heading?: string;
    id: number;
    latency?: number;
    location?: {
        lat: number;
        lng: number;
    };
    name?: string;
    networkStatus?: number;
    openStatus?: IVehicleOpenStatus;
    operateAble?: boolean;
    pwd?: string;
    startDeviceImei?: string;
    workingState?: IVehicleWorkingState;
    electricStatus?: number;
    innerShopName?: string;
}
export const getVehicleList: (innerShopId?: number) => Promise<{ vehicles: IVehicle[] } | null> = (innerShopId?: number) => {
    if (innerShopId === undefined) return Promise.resolve(null);
    return request({
        method: 'get',
        url: '/moria-api/monitorWeb/getVehicles',
        params: {
            innerShopId
        }
    }).then(d => d.data)
}
interface ITravelStationItem {
    id: number,
    name: string,
    lat: number,
    lon: number,
    isFinish: boolean,
    cnt: number,
    estimateArriveTime?: number,
    destToDestination?: number,
    autoRtnTime?: number,
    isArrived?: boolean
}
interface IVehiclePoint {
    lat: number,
    lng: number
}
export const getVehicle: (innerShopId?: number, vehicleId?: number) => Promise<{ vehicle: IVehicle, travelStations?: ITravelStationItem[], currentTarget?: ITravelStationItem, createTime?: number, points?: IVehiclePoint[][] } | null> = (innerShopId?: number, vehicleId?: number) => {
    if (innerShopId === undefined || vehicleId === undefined) return Promise.resolve(null);
    return request({
        method: 'get',
        url: '/moria-api/monitorWeb/getVehicleInfo',
        params: {
            innerShopId,
            vehicleId
        }
    }).then(d => d.data)
}

export enum DeviceStatus {
    POWER_OFF = 1,
    POWER_ON = 0,
    OFFLINE = 2
}

export const getVehicleRtc = (innerShopId?: number, vehicleId?: number) => {
    if (innerShopId === undefined || vehicleId === undefined) return Promise.resolve(null);
    return request({
        method: 'get',
        url: '/moria-api/monitorWeb/getRtc',
        params: {
            innerShopId,
            vehicleId
        }
    }).then(d => d.data)
}