import { useDeepCompareEffect, useMemoizedFn, useCreation, useRequest } from "ahooks";
import { useMemo, useRef, useState } from "react";
import { CameraType } from "../../utils/bind-rtc";
import { useIsExceptionHook } from "./isException.hook";
import 'baidurtc';
import { useCurrentVin } from "../currentVin.hook";
import constate from "constate";
import { IRemoteCommandBase, REMOTE_COMMAND_TYPE, useMqttSendMessage } from "../mqtt.hook";
import { useBrtcLogger } from "../logger.hook/brtcLogger.hook";
import { getVehicleRtc } from "../../api/vehicle.api";
import { useGlobalScope } from "../globalScope.hook";

declare global {
    var baidurtc: any;
}

interface BaiduRtcRoomClient {
    BRTC_Start: any;
    BRTC_GetRemoteUsers: () => { id: number; display: CameraType }[];
    BRTC_SubscribeStreaming: any;
    BRTC_StopSubscribeStreaming: (id: number) => void;
    BRTC_SetParamSettings: (param: {
        usingdatachannel?: boolean;
        usingaudio?: boolean;
        usingvideo?: boolean;
        videoprofile?: string[];
    }) => void;
    BRTC_StopPublish: () => void;
    BRTC_StartPublish: () => void;
    BRTC_GetRemoteAudioLevels: (id: number) => any;
    BRTC_Stop: () => void;
}
const copyVideo = (src: string, dest: string) => {
    const f = () => setTimeout(() => {
        const from = document.getElementById(src)!.getElementsByTagName('video').item(0)

        if (!from) {
            f()
            return
        }
        const srcObject = from.srcObject
        const destObject = document.getElementById(dest)
        const video = document.createElement('video')
        // video.width = '100%' as any
        video.muted = true
        video.autoplay = true
        video.srcObject = srcObject
        destObject?.appendChild(video)
    }, 1000)

    f()
}

export const [BrtcClientProvider, useBrtcClient] = constate(() => {
    const sendMessage = useMqttSendMessage()
    const { vehicleId } = useCurrentVin()
    const { innerShop } = useGlobalScope()
    const [streamMap, setStreamMap] = useState<{ [key: string]: number }>({})
    const conatinerMap = useCreation(() => new Map<number, string>(), [])
    const { data: vehicleRtcInfo } = useRequest(() => {
        return getVehicleRtc(innerShop?.innerShopId, vehicleId);
    }, { refreshDeps: [vehicleId] })

    const client: BaiduRtcRoomClient = useCreation(() => {
        console.log('new client create', vehicleId)
        return new baidurtc.BaiduRtcRoomClient()
    }, []);

    const removeStream = useMemoizedFn((id: number, display: string) => {
        const newStreamMap = Object.assign({}, streamMap)
        delete newStreamMap[display]
        console.log('removeStream', id, newStreamMap)
        setStreamMap(newStreamMap)
    })

    const addStream = useMemoizedFn((id: number, display: string) => {
        const newStreamMap = Object.assign({}, streamMap)
        newStreamMap[display] = id
        console.log('addStream', id, newStreamMap)
        setStreamMap(newStreamMap)
    })

    useDeepCompareEffect(() => {
        if (vehicleRtcInfo?.appId && vehicleRtcInfo?.roomName && vehicleRtcInfo?.token) {
            client.BRTC_Start({
                debuglevel: 'all',
                appid: vehicleRtcInfo.appId,
                roomname: vehicleRtcInfo.roomName,
                token: vehicleRtcInfo.token,
                userid: vehicleRtcInfo.rtcUid,
                displayname: 'remote_microphone',
                autosubscribe: false,
                autopublish: false,
                showvideobps: false,
                shownovideo: false,
                showspinner: false,
                aspublisher: true,
                usingvideo: false,
                receivingvideo: true,
                receivingaudio: true,
                autoplaymuted: true,
                remotevideocoming: function (id: number, display: string) {
                    addStream(id, display)
                },
                remotevideoleaving: function (id: number, display: string) {
                    removeStream(id, display)
                },
                remotevideooff: function (id: number, display: string) {
                    removeStream(id, display)
                },
                remotevideoon: function (id: number, display: string) {
                    addStream(id, display)
                }
            })
        }
        return () => {
            client?.BRTC_Stop();
        }
    }, [vehicleRtcInfo?.appId, vehicleRtcInfo?.roomName, vehicleRtcInfo?.token])
    const subscribeStream = useMemoizedFn((containerId: string, streamId: number) => {
        if (conatinerMap.has(streamId)) {
            copyVideo(conatinerMap.get(streamId)!, containerId)
        } else {
            conatinerMap.set(streamId, containerId)
            client.BRTC_SubscribeStreaming(containerId, streamId)
        }
    })

    const unsubscribeStream = useMemoizedFn((streamId: number, type: CameraType) => {
        conatinerMap.delete(streamId)
        client.BRTC_StopSubscribeStreaming(streamId)
        if (type === CameraType.TRAFFIC_LIGHT || type === CameraType.TRAFFIC_LIGHT_12MM) {
            sendMessage({
                cmd_type: REMOTE_COMMAND_TYPE.REMOTE_VIDEO_TOGGLE,
                cmd: type,
                auth: 'web_v1_direct_down'
            })
        }
    })

    const info = useMemo(() => ({
        roomname: vehicleRtcInfo?.roomName,
        userid: vehicleRtcInfo?.rtcUid,
        rearFlip: vehicleRtcInfo?.rearFlip
    }), [vehicleRtcInfo?.roomName, vehicleRtcInfo?.rearFlip, vehicleRtcInfo?.rtcUid])

    return { streamMap, subscribeStream, unsubscribeStream, client, info }
})

export const useBrtcAudio = (containerId: string) => {
    const { streamMap, client } = useBrtcClient()

    const open = useMemoizedFn(() => {
        if (!client) return
        client.BRTC_SetParamSettings({
            usingdatachannel: false,
            usingaudio: true,
            usingvideo: false,
        })
        client.BRTC_SubscribeStreaming(containerId, streamMap[CameraType.MICRO_PHONE])
        client.BRTC_StartPublish();
    })

    const close = useMemoizedFn(() => {
        if (!client) return
        client.BRTC_StopSubscribeStreaming(streamMap[CameraType.MICRO_PHONE])
        client.BRTC_StopPublish()
    })

    return { open, close }
}

export const useBrtcCamera = (type: CameraType, hash?: string) => {
    const { vehicleId } = useCurrentVin()
    const { streamMap, subscribeStream, unsubscribeStream, info } = useBrtcClient()
    const ref = useRef<HTMLDivElement>(null)
    const [status, setStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { isException } = useIsExceptionHook(ref)
    const sendMessage = useMqttSendMessage()
    const { start, stop } = useBrtcLogger()

    const toggleBtn = useMemoizedFn(() => {
        if (!status) {
            setIsLoading(true)
        }
        const msg: IRemoteCommandBase = {
            cmd_type: REMOTE_COMMAND_TYPE.REMOTE_VIDEO_TOGGLE,
            cmd: type,
            auth: 'web_v1_direct_on'
        }
        sendMessage(msg)
    })

    const containerId = useMemo(() => vehicleId + '_' + type + '_' + hash, [vehicleId, type, hash])

    useDeepCompareEffect(() => {
        const id = streamMap[type]
        if (id && ref?.current?.id) {
            setIsLoading(false)
            setStatus(true)
            console.log('BRTC_SubscribeStreaming', containerId, id)
            subscribeStream(containerId, id)
        } else {
            setIsLoading(false)
            setStatus(false)
        }
        return () => {
            console.log('BRTC_StopSubscribeStreaming', id)
            unsubscribeStream(id, type)
        }
    }, [streamMap[type]]);

    useDeepCompareEffect(() => {
        if (status && !isException) {
            start(type)
        } else {
            stop(type)
        }
    }, [isException, status])

    return {
        ref,
        status,
        isException,
        isLoading,
        toggleBtn,
        containerId,
        info
    }
}