import css from './index.module.scss'
import { useTrafficSignal } from "../../hooks/classic.hook/trafficSignal.hook"
import { useMemo } from "react"

export enum TrafficLightColor {
    EMPTY = 'UNKNOWN',
    RED = 'RED',
    GREEN = 'GREEN',
    YELLOW = 'YELLOW',
    BLACK = 'BLACK'
}

const trafficLightCssColor = {
    [TrafficLightColor.GREEN]: css.green,
    [TrafficLightColor.RED]: css.red,
    [TrafficLightColor.YELLOW]: css.yellow,
    [TrafficLightColor.BLACK]: css.black,
    [TrafficLightColor.EMPTY]: css.empty
}

export const TrafficLight = () => {
    const trafficSignal = useTrafficSignal()
    const leftColor = useMemo(
        () => trafficLightCssColor[trafficSignal?.tlLeft || TrafficLightColor.EMPTY],
        [trafficSignal]
    )

    const forwardColor = useMemo(
        () => trafficLightCssColor[trafficSignal?.tlForward || TrafficLightColor.EMPTY],
        [trafficSignal]
    )

    const rightColor = useMemo(
        () => trafficLightCssColor[trafficSignal?.tlRight || TrafficLightColor.EMPTY],
        [trafficSignal]
    )

    return <div className={css['traffic-light']}>
        <div className={`${css.light} ${leftColor}`} />
        <div className={`${css.light} ${forwardColor}`} />
        <div className={`${css.light} ${rightColor}`} />
    </div>
}