import { useMemo, useState } from 'react';
import css from './index.module.scss';
import { SearchBox } from '../SearchBox';
import { useCurrentUser } from '../../hooks/user.hook';
import { useGlobalScope } from '../../hooks/globalScope.hook';

const Select: React.FC<{ down: boolean }> = ({ children, down }) => <div className={`${css.select} ${down ? css.down : ''}`}>{children}</div>

const useSelectorHooks = () => {
    const [visible, setVisible] = useState(false)
    const [query, onSearch] = useState("")
    const { innerShopList } = useCurrentUser()
    const { innerShop, setInnerShop } = useGlobalScope()

    const searchedList = useMemo(() => {
        return innerShopList?.filter(i => i.innerShopName.indexOf(query) !== -1)
    }, [query, innerShopList])

    return { visible, setVisible, current: innerShop, setCurrent: setInnerShop, innerShopList: searchedList, onSearch }
}

export const InnerShopSwitch = () => {
    const { visible, setVisible, setCurrent, current, onSearch, innerShopList } = useSelectorHooks()

    return <div className={css['innerShop-switch']}>
        <div className={css.wrapper} onClick={() => setVisible(!visible)}>
            <Select down={visible}><div className={css.current}>
                {current?.innerShopName}
            </div></Select>
            {visible && <div className={css.mask}></div>}
            {visible && <div className={css['list']}>
                <ul>
                    <div className={css['search-box']}>
                        <SearchBox onChange={onSearch} placeholder='请输入门店信息'></SearchBox>
                    </div>
                    {
                        innerShopList?.map(i => <li key={i.innerShopId} onClick={(e) => {
                            e.stopPropagation()
                            setCurrent(i)
                            setVisible(false)
                        }} className={current?.innerShopId === i.innerShopId ? css.active : ''}><div>{i.innerShopName}</div></li>)
                    }
                </ul>
            </div>}
        </div>
    </div>
}