import { notification } from "antd";
import axios from "axios";
import cookie from 'js-cookie'

export const request = ({ method, url, params }) => {
    const uid = cookie.get('uid') || 0;
    const token = cookie.get('token') || '';
    return axios({
        method,
        url,
        params: params,
        headers: {
            uid,
            token
        }
    }).then(d => {
        return d.data
    }).then(d => {
        if (d.code !== 0) {
            if (d.code < 2000) {
                window.location.hash = '/login'
            } else if (d.code !== 6002) {
                notification.error({
                    message: d.msg || d.error
                })
            }
            throw d
        }
        return d
    })
}