import { useCurrentVin } from '../../hooks/currentVin.hook'
import { RouterPingInfo } from '../RouterRepairModal'
import { Speed } from '../Speed'
import { TrafficLight } from '../TrafficLight'
import css from './index.module.scss'

const CurrentVin = () => {
    const { vehicle } = useCurrentVin()
    return (
        <div className={css['current-vin']}>
            <em>{vehicle?.name}</em><span>车</span>
        </div>
    )
}

const VinSelect = () => {
    return <div className={`${css['vin-selector']}`}>
        <CurrentVin />
    </div>
}

const Close = () => {
    const { switchVehicle } = useCurrentVin()
    return <i className={css.close} onClick={() => switchVehicle(undefined)}></i>
}

export const Banner = () => <div className={css.banner}>
    <VinSelect />
    <div className={css['traffic-light']}><TrafficLight /></div>
    <div className={css['router-speed']}>
        <div className={css['speed']}><Speed /></div>
        <div className={css['router']}><RouterPingInfo /></div>
    </div>
    <Close />
</div>