import { RefObject } from "react";
import { useDeepCompareEffect } from "ahooks";
import { useCurrentVin } from "../../currentVin.hook";

export const useRoutePath = (gdMap: RefObject<any>) => {
    const { points } = useCurrentVin()
    
    useDeepCompareEffect(() => {
        if (!points) return;
        const paths = points.map((pos) => {
            return pos.map((point) => {
                return [point.lng, point.lat]
            })
        }).map((path: any[][]) => new AMap.Polyline({
            path: path,
            showDir: true,
            strokeColor: "#0E60F5",  //线颜色
            strokeOpacity: 1,     //线透明度
            strokeWeight: 6,      //线宽
            // strokeStyle: "solid"  //线样式
        }))
        paths.forEach(path => gdMap && gdMap.current?.add(path))
        return () => {
            paths.forEach(path => gdMap && gdMap.current?.remove(path))
        }
    }, [points])

    return
}