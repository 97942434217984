import { useMemoizedFn, useRequest } from "ahooks";
import constate from "constate"
import { useEffect, useRef, useState } from "react"
import { getVehicle } from "../api/vehicle.api";
import { useVehicleList } from "./vehicleList.hook";
import { useGlobalScope } from "./globalScope.hook";

export interface IVinType {
    vinId: string;
    shop: string;
    city?: string;
    utm_zone?: string;
}

const useCurrentVinInside = () => {
    const SCOPE_KEY_VIN = `current_vehicleId`;
    const { vehicleIds } = useVehicleList();
    const { innerShop } = useGlobalScope();
    const [vehicleId, setVehicleId] = useState<number | undefined>();
    const { data } = useRequest(() => getVehicle(innerShop?.innerShopId, vehicleId), { pollingInterval: 5000, refreshDeps: [vehicleId, innerShop?.innerShopId] })

    const setVinHandler = useMemoizedFn((vehicleId?: number, cb?: () => void) => {
        if (vehicleId !== undefined) {
            localStorage.setItem(SCOPE_KEY_VIN, JSON.stringify(vehicleId))
        } else {
            localStorage.removeItem(SCOPE_KEY_VIN)
        }
        setVehicleId(vehicleId)
    })

    useEffect(() => {
        const vehicleId = localStorage.getItem(SCOPE_KEY_VIN)
        let nVehicleId = undefined;
        if (vehicleId) {
            const vinId = JSON.parse(vehicleId)
            if (vehicleIds.includes(vinId)) {
                nVehicleId = vinId;
            }
        }
        if (!nVehicleId && vehicleIds.length > 0) {
            nVehicleId = vehicleIds[0];
        }
        setVinHandler(nVehicleId)
    }, [vehicleIds, SCOPE_KEY_VIN])

    return {
        vehicleId, switchVehicle: setVinHandler, vinStr: data?.vehicle?.name, vehicle: data?.vehicle, travelStations: data?.travelStations, currentTarget: data?.currentTarget, createTime: data?.createTime, points: data?.points
    }
}

export const [CurrentVinProvider, useCurrentVin] = constate(useCurrentVinInside);