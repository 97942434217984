import { Button, Form, Input } from 'antd'
import css from './index.module.scss'
import { useLogin } from './login.hook'
import { Header } from '../../components/Header'

export const Login = () => {
    const [form] = Form.useForm()
    const { limit, sendVerifyCode, login, loginError } = useLogin()

    return <div>
        <Header />
        <div className={css.bg}>
            <div className={css['wrap-border']}>
                <div className={css.wrap}>
                    <div className={css.content}>
                        <img alt='logo' src="./logo_login.svg" className={css.logo} />
                        <Form form={form}>
                            <div className={css.title}>短信登录</div>
                            <Form.Item className={css['input-item']} label={'短信登录'} labelCol={{ span: 0 }} name={'phone'} colon={false}>
                                <Input className={css['input']} autoComplete='off' placeholder='请输入手机号' prefix={<span className={`${css['icon-input']} ${css['icon-phone']}`}></span>}></Input>
                            </Form.Item>
                            <Form.Item className={css['input-item']} label={'验证码'} labelCol={{ span: 0 }} colon={false}>
                                <Input.Group compact style={{ display: 'flex' }}>
                                    <Form.Item noStyle name="verifyCode">
                                        <Input className={`${css['input']} ${css['input-verifyCode']}`} autoComplete='off' placeholder='请输入验证码' prefix={<span className={`${css['icon-input']} ${css['icon-verifyCode']}`}></span>} />
                                    </Form.Item>
                                    <Form.Item noStyle shouldUpdate={(prev, current) => prev.phone !== current.phone}>
                                        {
                                            v => {
                                                const phone = v.getFieldValue('phone') ?? ''
                                                return <div className={css['btn-verifyCode-wrap']}>
                                                    <Button className={css['btn-verifyCode']} type="primary" disabled={!phone.match(/^1[3456789]\d{9}$/) || (!!limit)} onClick={() => sendVerifyCode(phone)}>
                                                        {limit === 0 ? `发送验证码` : `${limit}秒`}
                                                    </Button>
                                                </div>
                                            }
                                        }
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                            <div className={css['bottom-wrap']}>
                                <div className={css['error-msg']}>{loginError}</div>
                                <div className={css['btn-login']} onClick={() => login(form.getFieldValue('verifyCode'), form.getFieldValue('phone'))}>登录</div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
}